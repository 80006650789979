require('./bootstrap');

require('./template/stisla')
require('./template/scripts')
require('./template/custom')

// $('#lightGallery').lightGallery({
//     thumbnail: true,
//     animateThumb: false,
//     showThumbByDefault: false
// });

$('[title]').tooltip()
