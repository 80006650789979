/**
 *
 * You can write your JS code here, DO NOT touch the default style file
 * because it will make it harder for you to update.
 *
 */

$(function () {
    // $('#lightGallery').lightGallery({
    //     thumbnail: true,
    //     animateThumb: false,
    //     showThumbByDefault: false
    // });

})
